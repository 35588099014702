import React, { createContext, useContext, useEffect, useState } from 'react'

import { CouponErrorBoundary } from '../components/CouponErrorBoundary/CouponErrorBoundary'
import { useGetCouponCode } from '../hooks/useGetCouponCode'
import { usePricingContext } from '../hooks/usePricingContextInitialization'
import { getLocalizationsFor } from '../localization/getLocalizations'
import { useCouponDetailsStore } from '../store/couponDetails.store'
import { BaseConfig } from '../store/couponDetails.types'
import { getMarketFromLocale } from '../utils/locale.util'

const CouponCallout = createContext<BaseConfig>({} as BaseConfig)
const CouponCalloutProvider = ({ baseConfig, children }: { baseConfig: BaseConfig, children: any }) => {
  const { tenant, locale, developmentMode, pageId } = baseConfig
  if (!tenant || !locale || !pageId) {
    throw new Error('Missing tenant, locale or pageId in baseConfig')
  }

  /**
   * Added isMounted logic to prevent hydration issues.
   * On server side, it will simply return the children i.e. the host page elements which is the expected behavior.
   */
  const [isMounted, setIsMounted] = useState<boolean>(false)
  useEffect(() => {
    setIsMounted(true)
  }, [])

  const market = getMarketFromLocale(locale) as string
  usePricingContext(tenant, market, developmentMode)

  const initializeScopedData = useCouponDetailsStore((state) => state.initializeScopedData)
  initializeScopedData(pageId)

  const setLocalizations = useCouponDetailsStore((state) => state.setLocalizations)

  useEffect(() => {
    const localizations = getLocalizationsFor(locale)
    setLocalizations(localizations)
  }, [locale])

  const couponCode = useGetCouponCode()
  const setCouponCode = useCouponDetailsStore((state) => state.setCouponCode)
  useEffect(() => {
    if (couponCode) {
      setCouponCode(couponCode)
    }
  }, [couponCode])

  if (!isMounted) {
    return <>{children}</>
  }

  return (
    <CouponErrorBoundary>
      <CouponCallout.Provider value={baseConfig}>
        {children}
      </CouponCallout.Provider>
    </CouponErrorBoundary>

  )
}

const useScopedBaseConfig = () => {
  return useContext(CouponCallout)
}
export { CouponCalloutProvider, useScopedBaseConfig }
