import React, { ReactNode, useEffect, useState } from 'react'

import { Box } from '@vp/swan'

type CSRWrapperProps = {
  children: ReactNode;
}

const CSRWrapper = ({ children }: CSRWrapperProps) => {
  const [uniqueID, setUniqueID] = useState<number | null>(null)

  useEffect(() => {
    setUniqueID(Math.random())
  }, [])

  if (!uniqueID) {
    return <></>
  }

  return <Box key={`coupon-callout-${uniqueID}`}>{children}</Box>
}

export { CSRWrapper }
